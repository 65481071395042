import React from "react";
import "./styles.css";

export default function Loader() {
  return (
    <div className="fixed z-[99999] flex items-center justify-center bg-gray-700 bg-opacity-5 w-full h-full top-0 left-0">
      <div className="flex gap-0 flex-col items-center">
        Please wait...
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
