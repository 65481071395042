import React, { useContext } from "react";
import { AppContext } from "../context";
import { TopNavComponent } from "./component";
function Index({ children }) {
  const {
    state: { isAuthenticated },
  } = useContext(AppContext);
  return (
    <div
      className={"grid h-screen "}
      style={{
        gridTemplateRows: isAuthenticated ? "80px 1fr" : "1fr",
      }}
    >
      {isAuthenticated && <TopNavComponent />}
      <div className={"grid overflow-y-scroll"}>{children}</div>
    </div>
  );
}

export default Index;
