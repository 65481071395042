import React from "react";
import { useField } from "formik";
import Select, { Props } from "react-select";

import { MySelectFieldInterface } from "./inputs.interface";
import "./styles.css";
import { SelectOptionInterface } from "interfaces";

export default function MySelectField({
  label,
  option,
  isRequired,
  isClearable,
  inputSize,
  customStyle,
  ...props
}: MySelectFieldInterface) {
  const [field, meta, helpers] = useField(props);
  let isError = meta.touched && meta.error;

  const isMultiple = Array.isArray(field?.value);

  const getSelectValue = () => {
    let value;
    if (isMultiple) {
      value = option?.selectOptions?.filter((e) => {
        return field.value?.some((value) => e?.value === value);
      });
    } else {
      value = option?.selectOptions?.find((e) => {
        return e?.value === field.value;
      });
    }
    return value;
  };

  let SelectProps: Props = {
    isClearable: true,
    isMulti: isMultiple,
    isDisabled: props?.disabled,
    className: "react-select-container",
    classNamePrefix: "custom-react-select",
    styles: {
      control: (provided, state) => ({
        ...provided,
        border: isError
          ? "2px solid red"
          : state.isFocused
          ? "2px solid #007bff"
          : provided.border,
        borderRadius: "5px",
        boxShadow: "none",
        height:
          inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
        width: customStyle?.width,
        minHeight:
          inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
        borderWidth: "2px",
        borderColor: "#e0e0e0",
        "&:hover": {
          borderColor: "#007bff",
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        // height:
        //   inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
        // minHeight:
        //   inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
        padding: "0 10px",
        fontSize:
          inputSize === "sm" ? "12px" : inputSize === "md" ? "14px" : "16px",
      }),
      input: (provided, state) => ({
        ...provided,
        margin: "0px",
        outline: "none",
        boxShadow: "none",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        whiteSpace: "nowrap",
      }),
      indicatorSeparator: (state) => ({
        ...state,
        display: "none",
        padding: "0px 0px",
      }),

      clearIndicator: (state) => ({
        ...state,
        padding: "0px 0px",
      }),
      dropdownIndicator: (state) => ({
        ...state,
        padding: "0px 5px",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        padding: "0px 0px",
        // minHeight:
        //   inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
        // height:
        //   inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
      }),
      menuPortal: (base) => ({ ...base, zIndex: 999 }),
    },
    value: field?.value ? getSelectValue() : "",
    options: option?.selectOptions,
    name: props.name,
    placeholder: option?.defaultLabel || "Select options",
    onChange: (newValue: SelectOptionInterface[] | SelectOptionInterface) => {
      if (Array.isArray(newValue)) {
        helpers.setValue(newValue?.map((e) => e?.value));
        if (typeof option?.callbackHandler === "function") {
          option?.callbackHandler(newValue?.map((e) => e?.value));
        }
      } else {
        helpers.setValue(newValue?.value || "");
        if (typeof option?.callbackHandler === "function") {
          option?.callbackHandler(newValue?.value);
        }
      }
    },
  };

  return (
    <div className="flex flex-col justify-end input_container ">
      <label
        htmlFor={field?.name}
        className={`input_label ${
          inputSize === "sm"
            ? "text-[12px]"
            : inputSize === "md"
            ? "text-[14px]"
            : "text-[16px]"
        }`}
      >
        <span>{label}</span>
        {isRequired && <span className={"text-red-500 text-[12px]"}>*</span>}
      </label>
      <Select
        {...SelectProps}
        id={field?.name}
        menuPortalTarget={document.body}
        maxMenuHeight={200}
      />

      {isError && (
        <span
          className={`input_textError ${
            inputSize === "sm"
              ? "text-[12px]"
              : inputSize === "md"
              ? "text-[14px]"
              : "text-[16px]"
          } `}
        >
          * {meta.error}
        </span>
      )}
    </div>
  );
}
