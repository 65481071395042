import React from "react";
import { useContext } from "react";
import { MyButton } from "components";

import { ConfirmContext } from "context";

export default function PromptModal() {
  const {
    prompt,
    isOpen = false,
    proceed,
    cancel,
  } = useContext(ConfirmContext);

  return (
    isOpen && (
      <div className="fixed flex items-center justify-center h-screen w-screen left-0 top-0 z-[99999] bg-gray-700 bg-opacity-75">
        <div className="flex flex-col  bg-white w-[300px]  rounded-lg  p-5 gap-4">
          <span className="font-bold text-[16px]">{prompt}</span>
          <div className="flex items-center justify-end gap-2">
            <MyButton
              colorType={"white"}
              name="Cancel"
              onClick={cancel}
              size={"md"}
            />
            <MyButton name="Confirm" onClick={proceed} size={"md"} />
          </div>
        </div>
      </div>
    )
  );
}
