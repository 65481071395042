import React, { useContext } from "react";
import { createContext } from "react";
import { Outlet } from "react-router-dom";
import { apiUrl } from "utils";
import { Api } from "services";
import { AppContext } from "context";

export const DataContext = createContext({
  loginHandler: (values, actions) => {},
});

export default function DataContextProvider() {
  const { postApi } = Api();
  const { handlers } = useContext(AppContext);

  const loginHandler = async (values, { setFieldError }) => {
    try {
      handlers.setLoadingState(true);
      let res;
      if (values?.isSystem) {
        res = await postApi(apiUrl.auth.post_login, values);
      } else {
        res = await postApi(apiUrl.student.post_login, values);
      }

      handlers?.loginHandler(res?.data, values?.isSystem);
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      handlers?.setLoadingState(false);
    }
  };

  let contextValue = {
    loginHandler,
  };
  return (
    <DataContext.Provider value={contextValue}>
      <Outlet />
    </DataContext.Provider>
  );
}
