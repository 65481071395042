import * as yup from "yup";
import { AdminInterface } from "interfaces";

export const UserFormik = {
  initialValues: (values?: AdminInterface) => ({
    ...(values?._id && { _id: values?._id }),
    firstName: values?.firstName,
    middleName: values?.middleName,
    lastName: values?.lastName,
    email: values?.email,
    password: values?.password,
    role: values?.role,
  }),
  validationSchema: yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().required("required"),
    role: yup.string().required("required"),
    password: yup.string(),
  }),
};
