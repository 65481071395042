import * as yup from "yup";

export const LoginFormik = {
  initialValues: (isSystem: boolean) => ({
    email: "",
    password: "",
    isSystem: isSystem,
  }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("Username or email is required"),
    password: yup.string().required("Password is required"),
  }),
};
