import React from "react";
import { Route } from "react-router-dom";
import HomePage from "./HomePage";

function Index() {
  return (
    <Route path="/">
      <Route index path={"home"} element={<HomePage />} />
    </Route>
  );
}

export default Index;
