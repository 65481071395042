import * as yup from "yup";
import { QuestionInterface } from "../../interfaces";

export const QuestionFormik = {
  initialValues: (values?: QuestionInterface) => ({
    ...(values?._id && { _id: values?._id }),
    question: values?.question,
    correctAnswer: values?.correctAnswer || 1,
    options: values?.options || [
      { text: "" },
      { text: "" },
      { text: "" },
      { text: "" },
    ],
  }),
  validationSchema: yup.object().shape({
    question: yup.string().required("required"),
    correctAnswer: yup.number().required("required"),
    options: yup.array().of(
      yup.object().shape({
        text: yup.string().required("required"),
      })
    ),
  }),
};
