import React from "react";
import { useSearchParams } from "react-router-dom";
import { QueryStringEnum } from "interfaces";

function UsePaginationQueryHooks(customCurrentPage?: any) {
  const pageLimit = 10;
  const [query, setQuery] = useSearchParams();
  const isArchive = query.get(QueryStringEnum.Archive);
  const currentPage =
    customCurrentPage || query.get(QueryStringEnum.CURRENT_PAGE) || "1";
  const setArchive = (status: boolean) => {
    if (status) {
      query.set(QueryStringEnum.Archive, "true");
    } else {
      query.delete(QueryStringEnum.Archive);
    }
    setQuery(query);
  };
  const calcSNHandler = (index: number) => {
    return index + 1 + (parseInt(currentPage) - 1) * pageLimit;
  };
  const setCurrentPage = (page) => {
    query.set(QueryStringEnum.CURRENT_PAGE, page);
    setQuery(query);
  };
  const paginationQuery = {
    pageSize: pageLimit,
    page: currentPage,
    archived: isArchive ? 1 : 0,
  };

  return {
    isArchive: isArchive ? true : false,
    currentPage: parseInt(currentPage),
    pageLimit,
    calcSNHandler,
    paginationQuery,
    setArchive,
    setCurrentPage,
  };
}

export default UsePaginationQueryHooks;
