import React from "react";
import { MyButton } from "components";
import { useNavigate } from "react-router-dom";

import { FormConcludeButtonInterface } from "./interface";

function FormConcludeButtons({
  submitButton,
  cancelButton,
  size,
  haveSubmitButton = true,
  haveCancelButton = false,
}: FormConcludeButtonInterface) {
  const navigate = useNavigate();
  return (
    <div className={"flex items-end justify-end border-t-2 pt-5"}>
      <div className={"flex gap-5"}>
        {haveCancelButton && (
          <MyButton
            size={size}
            isOutline
            colorType={"white"}
            type={cancelButton?.type || "button"}
            name={cancelButton?.title || "Cancel"}
            onClick={() => {
              if (typeof cancelButton?.handler == "function") {
                cancelButton.handler();
              } else {
                navigate(-1);
              }
            }}
          />
        )}
        {haveSubmitButton && (
          <MyButton
            size={size}
            disabled={submitButton?.disable}
            onClick={
              typeof submitButton?.handler === "function"
                ? () => submitButton?.handler()
                : undefined
            }
            colorType={"primary"}
            isLoading={submitButton?.isLoading}
            name={submitButton?.title || "Submit"}
            type={submitButton?.type || "submit"}
          />
        )}
      </div>
    </div>
  );
}

export default FormConcludeButtons;
