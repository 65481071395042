import { AuthTokenENUM } from "interfaces";

export const getAuthToken = (
  type: AuthTokenENUM = AuthTokenENUM.accessToken
) => {
  let token = localStorage.getItem(type);
  return token;
};

export const clearAuthToken = (
  type: AuthTokenENUM = AuthTokenENUM.accessToken
) => {
  localStorage.removeItem(type);
};

export const setAuthToken = (
  token: string,
  type: AuthTokenENUM = AuthTokenENUM.accessToken
) => {
  token = `Bearer ${token}`;
  localStorage.setItem(type, token);
};
export const setRole = (isSystem: boolean) => {
  localStorage.setItem(AuthTokenENUM.role, isSystem ? "system" : "student");
};

export const checkIsSystemRole = () => {
  const role = localStorage.getItem(AuthTokenENUM.role);
  if (role === "system") {
    return true;
  } else {
    return false;
  }
};
