import React from "react";
import { useAuthorizationHook } from "../hooks";
import { useNavigate } from "react-router-dom";
import { UserTypeEnum } from "../interfaces";

interface PropsInterface {
  children: any;
  isForPage?: boolean;
  role?: UserTypeEnum[];
}
function AccessVerifier({ children, isForPage = true, role }: PropsInterface) {
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAccess = checkCanAccessHandler(role);
  const navigate = useNavigate();
  if (canAccess) {
    return children;
  }
  if (!isForPage) {
    return <></>;
  }
  return (
    <div className={"flex flex-col gap-8 justify-center items-center "}>
      <div className={"text-center flex flex-col gap-1"}>
        <b className={"text-[32px] text-primary font-extrabold"}>403</b>
        <b className={"text-[32px] text-primary"}>Access Denied</b>
      </div>

      <div className={"text-center text-[16px] text-gray-400"}>
        <p>Sorry, you don't have permission to access this page.</p>
        <p>
          You can go back to{" "}
          <b
            className={"cursor-pointer text-primary"}
            onClick={() => navigate("/")}
          >
            Previous Page
          </b>
        </p>
      </div>
    </div>
  );
}

export default AccessVerifier;
