import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api } from "services";
import { apiUrl } from "utils";
import { DataContextInterface, AdminInterface } from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "hooks";

import { HandlerCallbackInterface } from "interfaces";
import { FormikHelpers } from "formik/dist/types";

export const AdminContext = createContext<DataContextInterface<AdminInterface>>(
  {
    isLoading: false,
    allData: [],
    isDetailsLoading: false,
    totalArchiveDocs: 0,
    details: null,
    totalDocs: 0,
    getAllDataHandlers(query?: any) {},
    getDetailsHandler(itemId: string, isFromEdit?: boolean) {},
    deleteHandler(itemId: string, callback?: HandlerCallbackInterface) {},
    editDetailsHandler(
      values,
      actions: FormikHelpers<unknown>,
      callback?: HandlerCallbackInterface
    ) {},
  }
);

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();

  //  handler
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);

        let res: any;
        res = await getApi(apiUrl.user.get_user, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(apiUrl.user.get_userDetails(itemID));
        setDetails(res?.data);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoadingState(true);
        const shouldEdit = values?._id;
        let res;
        if (shouldEdit) {
          res = await putApi(apiUrl.user.put_user, values);
        } else {
          res = await postApi(apiUrl.user.post_user, values);
        }
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccessState(true, res?.message);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoadingState(true);
        const res: any = await deleteApi(apiUrl.user.delete_user(itemsId), {});
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccessState(true, res?.message);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...contextHandlers,
  };
  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  );
}

export default DataContextProvider;
