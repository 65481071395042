import React from "react";
import { AiOutlineMore } from "react-icons/ai";
import Popup from "reactjs-popup";

import { MyMoreOptionsButtonInterface } from "./buttons.interface";

export default function MoreOptionsButton({
  options,
  width,
}: MyMoreOptionsButtonInterface) {
  return (
    <Popup
      position="bottom right"
      closeOnDocumentClick
      contentStyle={{ width: width || "200px" }}
      disabled={options?.length < 1}
      trigger={
        <button className="button" data-testid="more-options-button">
          <div className={"rounded-full p-2 border-2 text-gray-500 bg-white"}>
            <AiOutlineMore fontSize={16} />
          </div>
        </button>
      }
    >
      <div className={"flex flex-col "}>
        {options?.map((e, key) => {
          return (
            <div
              className={
                "flex items-center gap-2 border-b-2 px-2 py-2 text-primary text-[14px] cursor-pointer hover:bg-gray-100"
              }
              key={key}
              onClick={() => e.handler()}
            >
              <span>{e.name}</span>
            </div>
          );
        })}
      </div>
    </Popup>
  );
}
