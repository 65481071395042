import React, { useState } from "react";

function UseDataContextHooks() {
  const [isLoading, setLoading] = useState(false);
  const [isDetailsLoading, setDetailsLoading] = useState(false);

  const [totalDocs, setTotalDocs] = useState(0);
  const [totalArchiveDocs, setTotalArchiveDocs] = useState(0);

  const [allData, setAllData] = useState([]);
  const [details, setDetails] = useState(null);
  return {
    isLoading,
    setLoading,
    isDetailsLoading,
    setDetailsLoading,
    totalDocs,
    setTotalDocs,
    totalArchiveDocs,
    setTotalArchiveDocs,
    allData,
    setAllData,
    details,
    setDetails,
  };
}

export default UseDataContextHooks;
