import React, { useState } from "react";
import { useField } from "formik";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MyTextFieldInterface } from "./inputs.interface";
import "./styles.css";

export default function MyPasswordField({
  isRequired,
  ...props
}: MyTextFieldInterface) {
  const [field, meta] = useField(props);
  const [isToggle, setToggle] = useState(false);
  let isError = meta.touched && meta.error;
  const toggleHandler = () => {
    setToggle((e) => !e);
  };
  return (
    <div className="input_container ">
      <label htmlFor={props?.name} className={`input_label `}>
        {props?.label}
        {isRequired && <span className={"text-red-500 text-[18px]"}>*</span>}
      </label>{" "}
      <div
        className={`input_style border-gray-200 border-2  rounded-md flex justify-between  items-center gap-3 pr-5 ${
          isError && "border-red-500"
        }`}
      >
        <input
          id={props?.name}
          style={{
            border: "none",
            height: "30px",
            padding: 0,
          }}
          className="flex-1 text-[16px] border-transparent focus:border-transparent focus:ring-0"
          type={isToggle ? "text" : "password"}
          {...field}
          {...props}
        />
        <div
          data-testid={"passwordInput-toggle"}
          className=" cursor-pointer flex min-w-[20px] items-center justify-center"
          onClick={toggleHandler}
        >
          {isToggle ? <AiFillEye /> : <AiOutlineEyeInvisible />}
        </div>
      </div>
      {isError && <span className="input_textError">* {meta.error}</span>}
    </div>
  );
}
