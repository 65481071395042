import { IconTypeEnum } from "interfaces";
import { IoIosAdd } from "react-icons/io";
import { AiOutlineCheckCircle, AiOutlineCheck } from "react-icons/ai";
import { BsToggleOff, BsToggleOn, BsDownload } from "react-icons/bs";
import { BiTrash, BiWindowClose } from "react-icons/bi";
import { MdOutlineTimer } from "react-icons/md";

import { RiLockPasswordLine } from "react-icons/ri";

const getIconHelper = (iconType?: IconTypeEnum) => {
  switch (iconType) {
    case IconTypeEnum.PASSWORD:
      return RiLockPasswordLine;
    case IconTypeEnum.DOWNLOAD:
      return BsDownload;
    case IconTypeEnum.CHECK:
      return AiOutlineCheck;
    case IconTypeEnum.CLOSE:
      return BiWindowClose;
    case IconTypeEnum.UN_CHECK:
      return AiOutlineCheckCircle;
    case IconTypeEnum.TOGGLE_OFF:
      return BsToggleOff;
    case IconTypeEnum.TOGGLE_ON:
      return BsToggleOn;

    case IconTypeEnum.CLOCK:
      return MdOutlineTimer;
    case IconTypeEnum.ADD:
      return IoIosAdd;

    case IconTypeEnum.DELETE:
      return BiTrash;
    default:
      return IoIosAdd;
  }
};

export default getIconHelper;
