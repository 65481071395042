import React from "react";
import { Route } from "react-router-dom";
import { EditUserPage, UserPage } from "./pages";
import { ParamStringEnum, UserTypeEnum } from "../../interfaces";
import { AccessVerifier } from "../../templates";

function Index() {
  return (
    <Route path="/users">
      <Route
        path={""}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN]}>
            <UserPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"create"}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN]}>
            <EditUserPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamStringEnum.ID}`}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN]}>
            <EditUserPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
