import React from "react";
import ReactPaginate from "react-paginate";
import { usePaginationQueryHooks } from "hooks";

function PaginationComponent({
  totalDocs,
  styles,
  setCustomCurrentPage,
  customCurrentPage,
}: {
  totalDocs: number;
  styles?: { height?: string; width?: string };
  customCurrentPage?: number;
  setCustomCurrentPage?(value);
}) {
  let { currentPage, pageLimit, setCurrentPage } = usePaginationQueryHooks();
  if (customCurrentPage) {
    currentPage = customCurrentPage;
  }
  const onChangeHandler = ({ selected }) => {
    if (typeof setCustomCurrentPage === "function") {
      setCustomCurrentPage(selected + 1);
    } else {
      setCurrentPage(selected + 1);
    }
  };
  const totalPageCount = Math.ceil(totalDocs / pageLimit);
  return (
    <div
      className={
        "flex gap-5 lg:justify-between lg:items-center lg:py-5 lg:flex-row py-5 flex-col justify-start items-start flex-col-reverse"
      }
    >
      <div className={"16px text-[#00040E] "}>
        Showing{" "}
        {currentPage * pageLimit <= totalDocs
          ? pageLimit
          : Math.min(
              Math.abs(totalDocs - (currentPage - 1) * pageLimit) || 0,
              totalDocs || 0
            )}{" "}
        entries of {totalDocs || 0}.
      </div>
      <ReactPaginate
        forcePage={currentPage - 1}
        pageLinkClassName={`flex items-center justify-center ${
          styles?.height ? `h-[${styles?.height}]` : "h-[40px]"
        } ${styles?.width ? `w-[${styles?.width}]` : "w-[40px]"} `}
        activeClassName={`cursor-pointer flex items-center justify-center border-2 bg-primary text-white rounded-full ${
          styles?.height ? `h-[${styles?.height}]` : "h-[40px]"
        } ${styles?.width ? `w-[${styles?.width}]` : "w-[40px]"} `}
        pageClassName={`cursor-pointer  flex items-center justify-center text-black border-2 rounded-full ${
          styles?.height ? `h-[${styles?.height}]` : "h-[40px]"
        } ${styles?.width ? `w-[${styles?.width}]` : "w-[40px]"}`}
        disabledClassName={`cursor-pointer  flex items-center justify-center text-black border-2 rounded-full ${
          styles?.height ? `h-[${styles?.height}]` : "h-[40px]"
        } ${styles?.width ? `w-[${styles?.width}]` : "w-[40px]"}`}
        breakClassName={"item break-me "}
        breakLabel={"..."}
        containerClassName={"flex gap-5 items-center"}
        nextClassName={`cursor-pointer  flex items-center justify-center bg-white text-gray-600 rounded-full ${
          styles?.height ? `h-[${styles?.height}]` : "h-[40px]"
        } ${styles?.width ? `w-[${styles?.width}]` : "w-[40px]"}`}
        previousClassName={`cursor-pointer  flex items-center justify-center bg-white text-gray-600 rounded-full ${
          styles?.height ? `h-[${styles?.height}]` : "h-[40px]"
        } ${styles?.width ? `w-[${styles?.width}]` : "w-[40px]"}`}
        nextLabel=">"
        previousLabel="<"
        onPageChange={onChangeHandler}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        pageCount={totalPageCount}
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default PaginationComponent;
