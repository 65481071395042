import { useContext } from "react";
import { ConfirmContext } from "context";

const useConfirm = () => {
  const { setConfirm, isOpen } = useContext(ConfirmContext);

  const confirm = async (prompt) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });
    return promise.then(
      () => {
        setConfirm((e) => {
          return { ...e, isOpen: false };
        });
        return true;
      },
      () => {
        setConfirm((e) => {
          return { ...e, isOpen: false };
        });
        return false;
      }
    );
  };

  return {
    isOpen,
    confirm,
  };
};

export default useConfirm;
