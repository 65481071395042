import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { AdminContext } from "context";
import {
  MyButton,
  MyMoreOptionButton,
  PaginationComponent,
  TableComponent,
} from "components";
import {
  AdminInterface,
  IconTypeEnum,
  TableColumnInterface,
  UserTypeEnum,
} from "interfaces";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { usePaginationQueryHooks } from "hooks";

function UserPage() {
  const { getAllDataHandlers, deleteHandler, isLoading, allData, totalDocs } =
    useContext(AdminContext);
  const navigate = useNavigate();
  const { calcSNHandler, currentPage } = usePaginationQueryHooks();

  useEffect(() => {
    getAllDataHandlers();
  }, [currentPage]);
  function StudentTable(): TableColumnInterface<AdminInterface>[] {
    return [
      {
        title: "SN",
        render(renderData, key: number) {
          return <div>{calcSNHandler(key)}.</div>;
        },
      },
      {
        title: "Name",
        render(renderData) {
          return (
            <span>{`${renderData?.firstName || ""} ${
              renderData?.middleName || ""
            } ${renderData?.lastName || ""}`}</span>
          );
        },
      },
      {
        title: "Email",
        render(renderData) {
          return (
            <div className={"flex items-start "}>
              <span>{renderData?.email}</span>
            </div>
          );
        },
      },
      {
        title: "Role",
        render(renderData) {
          return (
            <div className={"flex items-start "}>
              <span>
                {renderData?.role === UserTypeEnum.ADMIN ? "Admin" : "Teacher"}
              </span>
            </div>
          );
        },
      },
      {
        title: "Action",
        render(renderData) {
          return (
            <div>
              <MyMoreOptionButton
                options={[
                  {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.user.edit(renderData?._id));
                    },
                  },
                  {
                    name: "Delete",
                    handler() {
                      deleteHandler(renderData?._id, {
                        onSuccess(payload?: any): any {
                          getAllDataHandlers();
                        },
                      });
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }

  return (
    <PageTemplate
      title={"Users"}
      rightChildren={
        <div>
          <MyButton
            name={"Add Users"}
            onClick={() => navigate(PageLinks.user.create)}
            iconType={IconTypeEnum.ADD}
          />
        </div>
      }
    >
      <TableComponent
        column={StudentTable()}
        dataSource={allData}
        loading={{
          isLoading: isLoading,
        }}
      />
      <PaginationComponent totalDocs={totalDocs} />
    </PageTemplate>
  );
}

export default UserPage;
