import React, { useContext } from "react";
import { AppContext } from "../context";
import { UserTypeEnum } from "interfaces";

function UseAuthorizationHook() {
  const {
    state: { profileDetails },
  } = useContext(AppContext);
  const checkCanAccessHandler = (role: UserTypeEnum[]): boolean => {
    return role?.some((e) => e === profileDetails?.role);
  };
  return {
    checkCanAccessHandler,
  };
}

export default UseAuthorizationHook;
