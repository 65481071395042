import axios from "axios";

import { apiConfig } from "configs";

import { clearAuthToken, getAuthToken } from "../utils/authUtils";
import { AuthTokenENUM } from "../interfaces";

function Api() {
  const handleError = (error) => {
    if (error?.response?.status === 401) {
      clearAuthToken(AuthTokenENUM.accessToken);
      throw new Error("Login expired");
    } else if (error?.response?.status === 404) {
      throw new Error("Routes not found");
    } else {
      if (error?.response?.data) {
        throw error?.response?.data;
      } else {
        throw new Error(error?.message) || error?.response;
      }
    }
  };
  const handleSuccess = (response) => {
    if (response?.status === 200) {
      return response?.data;
    }
    return response;
  };
  // For common config
  const apiAxiosInstance = () => {
    let axiosInstance = axios.create({
      baseURL: apiConfig?.default,
      headers: {
        authorization: getAuthToken(),
        "Content-Type": "application/json",
      },
    });
    axiosInstance.interceptors.response.use(handleSuccess, handleError);
    return axiosInstance;
  };

  async function getApi(url: string, body?: any) {
    try {
      let res: any = await apiAxiosInstance().get(url, {
        params: body,
      });
      return res;
    } catch (err: any) {
      throw err;
    }
  }

  async function getBlobResApi(url: string, body?: any) {
    try {
      let res: any = await apiAxiosInstance().get(url, {
        params: body,
        responseType: "blob",
      });
      return res;
    } catch (err: any) {
      throw err;
    }
  }

  async function postApi(url: string, body: any) {
    try {
      let res = await apiAxiosInstance().post(url, body);
      return res;
    } catch (err: any) {
      throw err;
    }
  }

  async function putApi(url: string, body?: any) {
    try {
      let res = await apiAxiosInstance().put(url, body);
      return res;
    } catch (err: any) {
      throw err;
    }
  }

  async function deleteApi(url: string, body?: any) {
    try {
      let res: any = await apiAxiosInstance().delete(url, {
        data: body,
      });
      return res;
    } catch (err: any) {
      throw err;
    }
  }

  async function postFormApi(url: string, body?: any) {
    try {
      let res = await apiAxiosInstance().post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  return {
    getApi,
    postApi,
    putApi,
    deleteApi,
    postFormApi,
    getBlobResApi,
    utils: { handleError, handleSuccess },
  };
}

export default Api;
